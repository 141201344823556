.detail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100vw;
    margin-left: -4%;
    padding: 0px 16px;
    margin-top: -88px;
}

.detail__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 0;
}

.detail__title::after {
    content: '...';
    position: absolute;
    right: calc(23%);
}

.detail__title {
    white-space: nowrap;
    width: 55%;
    overflow: hidden;
    color: #011210;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
}

.detail__photo {
    width: 100%;
    display: flex;
    justify-content: center;

}

.detail__photo img {
    width: 100%;
    max-width: 296px;

    height: 342px;
    border-radius: 10px;
}

.detail__name {
    color: #011210;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 20px
;
}

.detail__price {
    border-radius: 10px;
    background: #FFFFFF;
    width: 100vw;
    margin-left: -16px;
    padding: 16px 16px;
    margin-top: 20px;

}

.detail__price-small {
    color: #0CE3CB;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    margin-bottom: 3px;
    opacity: 0.7;
}

.detail__price-price {
    color: #0CE3CB;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.detail__price-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.detail__btn, .cart-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #0CE3CB;
    border-radius: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    color: #fff;
    position: sticky;
    bottom: 10px;
    z-index: 1000;
}




.detail__price-line {
    color: #888;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.5px;
    text-decoration-line: line-through;
    opacity: 0.65;
}

.detail__about-title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
}

.detail__price-text {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: .8;
    margin-top: 8px;
    background: #FFFFFF;
    margin-left: -16px;
    padding: 16px 16px;
    width: 100vw;
}
.button-disabled {
    background-color: grey;
}


@media screen and (min-width: 1000px) {
    .detail__name{
        display: none;
    }

    .detail__content{
        display: flex;
        gap: 61px;
        width: 100%;
    }

    .detail__photo {
        width: 449px;

    }

    .detail__photo img{
        max-width: 449px;

    }

    .detail__price{
        width: 390px;
        height: 96px;
    }

    .detail_pc{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        max-width: 870px;
        width: 100%;

        margin-left: 0;
        padding: 0px 16px;
        margin-top: -88px;
    }

    .detail__about, .detail__price-text{
        margin-left: 0;
        width: 100%;

    }

    .detail__title::after {
        display: none;
    }

}

.disn{
    display: none;
}
