@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
    padding: 0;
    margin: 0;
    border: 0;
    font-family: "Open Sans", sans-serif;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    background: #F7F7F7;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.dn {
    display: none;
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style-type: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: 400;
}

.container {
    width: 100%;
    max-width: 1410px;
    margin: 0 auto;
    padding-top: 88px; /* Высота вашего хедера */

}

.header {
    width: 100%;
    height: auto;
    border-radius: 0px 0px 10px 10px;
    background: #0CE3CB;
    position: fixed;
}

.header .before__img {
    margin-right: 8px;
}

.header__container {
    width: 100%;
    margin: 0 auto;
    max-width: 1410px;
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: open sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
}

.header__btn {
    border-radius: 10px;
    background: rgba(254, 254, 254, 0.196);
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 11px;
}

.header__search {
    width: 857px;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #FEFEFE;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    letter-spacing: -0.3px;
}

.header__search input {
    background: transparent;
    width: 100%;
    color: #0CE3CB;
    padding: 0 20px;
    height: 100%;
}

ul {
    display: flex;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 10px;
}

li {
    list-style: none;

}

.fa {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order__user {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    height: 50px;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    justify-content: center;
    line-height: 30px;
}

.order__product button {
    width: 100px;
    height: 100%;
    background: transparent;
}

.order__product {
    justify-content: space-between;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    /*flex-direction: column;*/
    display: flex;
    min-height: 100px;
    height: fit-content;
    padding: 20px;
    font-size: 20px;
    line-height: 30px;
}

.order__product-p {
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    /*flex-direction: column;*/
    display: flex;
    min-height: 100px;
    height: fit-content;
    padding: 20px;
    font-size: 20px;
    line-height: 30px;
}

.status-2 {
    background-color: rgba(0, 187, 255, 0.62);
}

.status-3 {
    background-color: rgba(0, 128, 0, 0.48);
}


a {
    color: inherit;
    text-decoration: none;
}

.btn-active {
    background-color: #008CBA; /* Синий цвет */
    color: white;
}
